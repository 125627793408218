.App {
    font-family: sans-serif;
    text-align: center;
}
body{
    width: 100%;
    height: 100%;
    position: absolute;
    margin: 0;
}
